import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@pl/app-component-lib";
import { usePostUserLogin } from "@pl/app-services";

export type GuestData = {
  checkoutId: string;
};

export type GuestProps = {
  onSuccess: () => void;
  guestData: GuestData;
};

export default function Guest({ onSuccess, guestData }: GuestProps) {
  const { formatMessage } = useIntl();
  const { login, isSuccess, isLoading } = usePostUserLogin();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  function handleButtonClick() {
    login({
      mode: "guest",
      checkoutId: guestData.checkoutId,
    });
  }

  return (
    <>
      <div className="mb-8">
        <div className="flex items-center">
          <hr className="border-border-divider bg-border-divider flex-grow" />
          <span className="text-body-md mx-4 flex-shrink">or</span>
          <hr className="border-border-divider bg-border-divider flex-grow" />
        </div>
      </div>
      <Button
        block
        type="secondary"
        text={formatMessage({ id: "guest-account.action.checkout-as-guest" })}
        onClick={handleButtonClick}
        loading={isLoading}
      />
    </>
  );
}
