import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetUsersPersonasResponse,
  PatchUserRequest,
  PutSwitchAccessRequest,
  getUsersPersonas,
  patchUser,
  putSwitchAccess,
} from "#app-services/api/users";
import { setAuthToken } from "#app-services/utils/auth/jwt";
import { ReactQueryKeys } from "./constants";
import { ServiceHook } from "./types";

export type UsePutSwitchAccess = ServiceHook<undefined, null> & {
  putSwitchAccess: (request: PutSwitchAccessRequest) => void;
  reset: () => void;
};

export function usePutSwitchAccess(): UsePutSwitchAccess {
  const { isPending, isSuccess, isError, mutate, reset } = useMutation({
    mutationKey: [ReactQueryKeys.putSwitchAccess],
    mutationFn: async (request: PutSwitchAccessRequest) => {
      const response = await putSwitchAccess(request);

      if (!response.success) {
        throw new Error();
      }

      setAuthToken(response.data.jwt);
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    putSwitchAccess: mutate,
    reset,
  };
}

export type UseGetUsersPersonas = ServiceHook<GetUsersPersonasResponse, null>;

export function useGetUsersPersonas(enabled = true): UseGetUsersPersonas {
  const { isPending, isSuccess, isError, data } = useQuery({
    queryKey: [ReactQueryKeys.getUsersPersonas],
    staleTime: Infinity,
    enabled,
    queryFn: async () => {
      const response = await getUsersPersonas();

      if (!response.success) {
        throw new Error();
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    data,
  };
}

export type UsePatchUser = ServiceHook<undefined, null> & {
  patchUser: (request: PatchUserRequest) => void;
};

export function usePatchUser(): UsePatchUser {
  const { isPending, isSuccess, isError, mutate } = useMutation({
    mutationKey: [ReactQueryKeys.patchUser],
    mutationFn: async (request: PatchUserRequest) => {
      const response = await patchUser(request);

      if (!response.success) {
        throw new Error();
      }
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    patchUser: mutate,
  };
}
