import { SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Message } from "@pl/app-component-lib";
import {
  useCountdown,
  usePostRequestNew2faCode,
  usePostUserLogin,
} from "@pl/app-services";
import { RhfInput } from "#app-common/components/RhfInput";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";
import { validateNonEmptyString } from "#app-common/models/validators";
import { RememberDevice } from "./RememberDevice";

const INITIAL_DURATION = 60;

function render2faMessage(hasRequestedNew2faCode: boolean) {
  if (hasRequestedNew2faCode) {
    return (
      <Message
        status="positive"
        body={<FormattedMessage id="login.2fa.message.token-requested" />}
      />
    );
  } else {
    return (
      <Message
        status="info"
        body={<FormattedMessage id="login.2fa.message.require-code" />}
      />
    );
  }
}

function TwoFactorWrapped({
  onSuccess,
  payorId,
  withRememberDevice,
}: TwoFactorProps) {
  const { login, isLoading, isSuccess, data, isError, error } =
    usePostUserLogin();
  const { formatMessage, formatNumber } = useIntl();
  const router = usePaymentLabsRouter();
  const {
    requestNew2faCode,
    isLoading: isRequestNew2faCodeLoading,
    isSuccess: isRequestNew2faCodeSuccess,
    isError: isRequestNew2faCodeError,
    error: requestNew2faCodeError,
  } = usePostRequestNew2faCode();

  const { handleSubmit, getValues } = useFormContext<TwoFactorForm>();

  const handleClick = () => {
    requestNew2faCode({ refresh: true });
  };

  const { secondsRemaining, isTimeRemaining, restart } = useCountdown(
    INITIAL_DURATION,
    true
  );

  useEffect(() => {
    if (isRequestNew2faCodeSuccess) {
      restart();
    }
  }, [isRequestNew2faCodeSuccess, restart]);

  useEffect(() => {
    if (isSuccess) {
      if (data === "SUCCESS") {
        onSuccess("success");
      } else if (data === "NEEDS_CHANGE_PASSWORD") {
        onSuccess("changePassword");
      }
    }
  }, [isSuccess, data, onSuccess]);

  function handleSignIn(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { token, trustThisDevice } = getValues();
    handleSubmit(() => login({ token, trustThisDevice, payorId }))();
  }

  const requestNewCodeText = formatMessage({
    id: "login.2fa.action.request-new-code",
  });
  const requestNewCodeCounterText = `(${formatNumber(secondsRemaining, {
    unit: "second",
    unitDisplay: "narrow",
    style: "unit",
  })})`;
  const requestNewCodeButtonText = [
    requestNewCodeText,
    secondsRemaining > 0 && !isLoading ? requestNewCodeCounterText : undefined,
  ]
    .filter(Boolean)
    .join(" ");

  let errorMessage = null;
  if (
    (isError && error) ||
    (isRequestNew2faCodeError && requestNew2faCodeError)
  ) {
    errorMessage = (
      <FormattedMessage id="global.error.api.something-went-wrong" />
    );
  } else if (data === "BAD_2FA") {
    errorMessage = <FormattedMessage id="login.2fa.error.invalid-code" />;
  }

  if (isRequestNew2faCodeError && requestNew2faCodeError) {
    if (requestNew2faCodeError?.code === "FORBIDDEN") {
      router.goToSessionExpired();
    }
  }

  return (
    <>
      <h1 className="text-txt-heading text-heading-xs mb-6">
        <FormattedMessage id="login.heading" />
      </h1>
      {errorMessage && (
        <div className="mb-4">
          <Message status="negative" body={errorMessage} />
        </div>
      )}
      {!errorMessage && (
        <div className="mb-4">
          {render2faMessage(isRequestNew2faCodeSuccess)}
        </div>
      )}
      <form onSubmit={handleSignIn}>
        <RhfInput
          id="token"
          label={formatMessage({
            id: "login.2fa.token.label",
          })}
          validate={(value) => validateNonEmptyString({ value, formatMessage })}
          placeholder={formatMessage({
            id: "login.2fa.token.placeholder",
          })}
        />

        {withRememberDevice && (
          <div className="mb-6">
            <RememberDevice />
          </div>
        )}

        <div className="grid grid-cols-2 gap-4 *:col-span-2 *:sm:col-span-1">
          <div className="order-2 sm:order-1">
            <Button
              block
              type="secondary"
              htmlType="button"
              text={requestNewCodeButtonText}
              onClick={handleClick}
              disabled={isTimeRemaining || isLoading}
              loading={isRequestNew2faCodeLoading}
            />
          </div>
          <div className="order-1 sm:order-2">
            <Button
              block
              type="primary"
              htmlType="submit"
              text={formatMessage({
                id: "login.action.sign-in",
              })}
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </>
  );
}

type TwoFactorForm = { token: string; trustThisDevice: boolean };

export type TwoFactorProps = {
  onSuccess: (step: "changePassword" | "success") => void;
  payorId?: string;
  withRememberDevice?: boolean;
};

export function TwoFactor(props: TwoFactorProps) {
  const rhfMethods = useForm<TwoFactorForm>({
    mode: "onTouched",
  });

  return (
    <FormProvider {...rhfMethods}>
      <TwoFactorWrapped {...props} />
    </FormProvider>
  );
}
