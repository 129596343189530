import { FormattedMessage, useIntl } from "react-intl";
import cx from "classnames";
import { Button, Message, MessageStatus } from "@pl/app-component-lib";
import { useCountdown, usePutPayeeResendInvitation } from "@pl/app-services";

const INITIAL_DURATION = 120;

export type PendingActivationProps = {
  payeeEmail: string;
  payeeReferenceId: string;
};

export function PendingActivation({
  payeeEmail,
  payeeReferenceId,
}: PendingActivationProps) {
  const { formatMessage, formatNumber } = useIntl();
  const { putPayeeResendInvitation, isLoading, isSuccess, isError } =
    usePutPayeeResendInvitation();

  const { secondsRemaining, isTimeRemaining } = useCountdown(
    INITIAL_DURATION,
    isSuccess
  );

  function handleResendClick() {
    putPayeeResendInvitation({ payeeEmail, payeeReferenceId });
  }

  let messageDetail: {
    status: (typeof MessageStatus)[number];
    message: string;
  } = {
    status: "warning",
    message: formatMessage({ id: "login.pending-activation.message" }),
  };

  if (isSuccess) {
    messageDetail = {
      status: "positive",
      message: formatMessage({
        id: "login.pending-activation.resend.success",
      }),
    };
  } else if (isError) {
    messageDetail = {
      status: "negative",
      message: formatMessage({
        id: "global.error.api.something-went-wrong-try-again",
      }),
    };
  }

  const buttonText = cx(
    formatMessage({
      id: "login.pending-activation.resend.label",
    }),
    {
      [`(${formatNumber(secondsRemaining, {
        unit: "second",
        unitDisplay: "narrow",
        style: "unit",
      })})`]: secondsRemaining !== 0,
    }
  );

  return (
    <>
      <h1 className="text-txt-heading text-title-lg mb-6">
        <FormattedMessage id="login.pending-activation.heading" />
      </h1>
      <div className="mb-8">
        <Message status={messageDetail.status} body={messageDetail.message} />
      </div>
      <Button
        block
        type="primary"
        onClick={handleResendClick}
        text={buttonText}
        loading={isLoading}
        disabled={isTimeRemaining}
      />
    </>
  );
}
