import { AxiosError, AxiosResponse } from "axios";
import { AccountType } from "#app-services/stores/useAuthStore";
import { instance } from "./config";
import { ApiEndpoints } from "./endpoints";
import { ServiceResponse } from "./types";

export type UserPersona = {
  payorId: string;
  displayName: string;
  permission: AccountType;
};

export type GetUsersPersonasResponse = UserPersona[];

export async function getUsersPersonas(): Promise<
  ServiceResponse<GetUsersPersonasResponse, null>
> {
  let response: AxiosResponse<GetUsersPersonasResponse>;
  const url = ApiEndpoints.getUsersPersonas();

  try {
    response = await instance.get(url);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PutSwitchAccessResponse = {
  jwt: string;
};

export type PutSwitchAccessRequest = {
  payorId: string;
  accessRole: Omit<AccountType, "merchant">;
};

export async function putSwitchAccess(
  request: PutSwitchAccessRequest
): Promise<ServiceResponse<PutSwitchAccessResponse, null>> {
  let response: AxiosResponse<PutSwitchAccessResponse>;
  const url = ApiEndpoints.putSwitchAccess();

  try {
    response = await instance.put(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PatchUserRequest = {
  firstName: string;
  lastName: string;
};

export async function patchUser(
  request: PatchUserRequest
): Promise<ServiceResponse<undefined, null>> {
  let response: AxiosResponse;
  const url = ApiEndpoints.patchUser();

  try {
    response = await instance.patch(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: undefined,
    status: response.status,
    raw: response,
  };
}
