import { forwardRef } from "react";
import cx from "classnames";
import { FieldGutterNew } from "#app-component-lib/form/common/FieldGutter";
import {
  FieldLabel,
  FieldLabelProps,
} from "#app-component-lib/form/common/FieldLabel";
import { computeFieldState } from "#app-component-lib/form/common/utils";
import "./Checkbox.css";

export type CheckboxCoreProps = {
  id: string;
  name: string;
  label: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  hintMessage?: string;
  isValid?: boolean;
  error?: string;
  withGutter?: boolean;
  ariaDescribedBy?: string;
  tooltip?: {
    id: string;
    message: string;
    triggerTitle: string;
  };
  badge?: FieldLabelProps["badge"] & {
    id: string;
  };
  onChange: (checked: boolean) => void;
  onBlur?: () => void;
};

export const CheckboxCore = forwardRef<HTMLInputElement, CheckboxCoreProps>(
  (
    {
      id,
      name,
      label,
      checked = false,
      indeterminate = false,
      disabled,
      hintMessage,
      isValid,
      error,
      withGutter = true,
      ariaDescribedBy,
      tooltip,
      badge,
      onChange,
      onBlur,
    },
    ref
  ) => {
    const fieldState = computeFieldState(isValid, Boolean(error), disabled);

    return (
      <>
        <div className="flex items-center gap-x-2">
          <input
            aria-describedby={ariaDescribedBy}
            aria-checked={indeterminate ? "mixed" : undefined}
            aria-invalid={fieldState === "error"}
            data-is-error={fieldState === "error"}
            data-is-valid={fieldState === "valid"}
            // Adapted from https://moderncss.dev/pure-css-custom-styled-radio-buttons/
            className={cx(
              "appearance-none outline-none",
              "flex flex-shrink-0 items-center justify-center self-start",
              "before:flex before:items-center before:justify-center",
              "before:rounded-2xs rounded-2xs",
              "size-6 before:size-full before:scale-0 checked:before:scale-100",
              "focus:shadow-spread focus:shadow-int-info",
              "checked:border-int-border-info border-int-border-form disabled:border-int-border-disabled border",
              "disabled:before:bg-int-bg-disabled before:bg-int-bg-info",
              "bg-int-bg-secondary disabled:bg-int-bg-disabled checked:bg-int-bg-info",
              "before:duration-200 before:ease-in-out",
              // There's like, a pixel border between the edge of the background
              // image and checkbox container. It's not visible if the container
              // has a background color when checked. But we ease it in/out so
              // it transitions along with the SVG icon.
              "checked:duration-300 checked:ease-in-out",
              "before:bg-center before:bg-no-repeat",
              // See comment in Radio
              "will-change-transform",
              // Technically you can trigger the :intermediate pseudoselector
              // via JS, but this is simpler and works just the same
              {
                indeterminate: indeterminate,
              }
            )}
            id={id}
            name={name}
            type="checkbox"
            ref={ref}
            checked={checked || indeterminate}
            disabled={disabled}
            onChange={(e) => onChange(e.target.checked)}
            onBlur={onBlur}
          />
          <FieldLabel
            as="label"
            htmlFor={id}
            text={label}
            labelFontSize="sm"
            wrapperMargin="none"
            tooltip={tooltip}
            badge={badge}
          />
        </div>
        {withGutter && (
          <FieldGutterNew
            id={id}
            fieldState={fieldState}
            hintMessage={hintMessage}
            errorMessage={error}
            wrapperMargin="md"
          />
        )}
      </>
    );
  }
);
CheckboxCore.displayName = "CheckboxCore";
