import { SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Message, RenderStateSection } from "@pl/app-component-lib";
import { useGetUser, usePatchUser } from "@pl/app-services";
import { RhfInput } from "#app-common/components/RhfInput";
import { validateNonEmptyString } from "#app-common/models/validators";

function SetUserInfoWrapped({ onSuccess }: SetUserInfoProps) {
  const { formatMessage } = useIntl();
  const { patchUser, isLoading, isSuccess, data, isError } = usePatchUser();
  const { handleSubmit, getValues } = useFormContext<SetUserInfoForm>();
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isSuccess: isSuccessUserInfo,
  } = useGetUser();

  useEffect(() => {
    if (isSuccessUserInfo && userInfo) {
      if (userInfo.firstName && userInfo.lastName) {
        onSuccess("success");
      }
    }
  }, [isSuccessUserInfo, userInfo, onSuccess]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess("success");
    }
  }, [isSuccess, data, onSuccess]);

  function handleSignIn(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { firstName, lastName } = getValues();

    handleSubmit(() =>
      patchUser({
        firstName,
        lastName,
      })
    )();
  }

  let errorMessage = null;
  if (isError) {
    errorMessage = (
      <FormattedMessage id="global.error.api.something-went-wrong" />
    );
  }

  return (
    <>
      {isLoadingUserInfo && (
        <RenderStateSection variant="loading" height="lg" />
      )}
      {!isLoadingUserInfo && isSuccessUserInfo && (
        <>
          <h1 className="text-txt-heading text-title-lg mb-6">
            <FormattedMessage id="login.set-user-info.heading" />
          </h1>
          {errorMessage && (
            <div className="mb-4">
              <Message status="negative" body={errorMessage} />
            </div>
          )}
          <form onSubmit={handleSignIn}>
            <RhfInput
              id="email"
              label={formatMessage({ id: "global.label.email" })}
              disabled
              defaultValue={userInfo?.email}
              validate={(value) =>
                validateNonEmptyString({ value, formatMessage })
              }
            />

            <RhfInput
              id="firstName"
              label={formatMessage({ id: "global.label.firstName" })}
              validate={(value) =>
                validateNonEmptyString({ value, formatMessage })
              }
            />

            <RhfInput
              id="lastName"
              label={formatMessage({ id: "global.label.lastName" })}
              validate={(value) =>
                validateNonEmptyString({ value, formatMessage })
              }
            />

            <Button
              block
              type="primary"
              htmlType="submit"
              text={formatMessage({ id: "global.action.submit" })}
              loading={isLoading}
            />
          </form>
        </>
      )}
    </>
  );
}

export type SetUserInfoProps = {
  onSuccess: (step: "success") => void;
};

type SetUserInfoForm = {
  firstName: string;
  lastName: string;
};

export function SetUserInfo(props: SetUserInfoProps) {
  const rhfMethods = useForm<SetUserInfoForm>({
    mode: "onTouched",
  });

  return (
    <FormProvider {...rhfMethods}>
      <SetUserInfoWrapped {...props} />
    </FormProvider>
  );
}
