import { forwardRef } from "react";
import cx from "classnames";
import { BadgeProps } from "#app-component-lib/data-display/Badge/Badge";
import {
  buildAriaDescribedBy,
  computeFieldState,
} from "#app-component-lib/form/common/utils";
import { CheckboxCore, CheckboxCoreProps } from "./CheckboxCore";

export type CheckboxProps = Omit<
  CheckboxCoreProps,
  "isGrouped" | "tooltip" | "badge"
> & {
  tooltip?: {
    message: string;
    triggerTitle: string;
  };
  badge?: BadgeProps & {
    asStatus?: boolean;
  };
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      label,
      checked,
      indeterminate,
      disabled,
      hintMessage,
      isValid,
      error,
      ariaDescribedBy,
      withGutter,
      tooltip,
      badge,
      onChange,
      onBlur,
    },
    ref
  ) => {
    const fieldState = computeFieldState(isValid, Boolean(error), disabled);
    const ttId = tooltip ? `checkbox-${id}-tt` : undefined;
    const badgeId = badge ? `checkbox-${id}-badge` : undefined;

    return (
      <CheckboxCore
        ref={ref}
        id={id}
        name={name}
        label={label}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        hintMessage={hintMessage}
        isValid={isValid}
        error={error}
        ariaDescribedBy={cx(
          badgeId,
          buildAriaDescribedBy({
            fieldState,
            ariaDescribedBy,
            hasHintMessage: Boolean(hintMessage),
            hasErrorMessage: Boolean(error),
            fieldId: id,
            tooltipId: ttId,
          })
        )}
        withGutter={withGutter}
        tooltip={tooltip ? { ...tooltip, id: ttId as string } : undefined}
        badge={badge ? { ...badge, id: badgeId as string } : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
);
Checkbox.displayName = "Checkbox";
