import { IconProps } from "../types";
import { getIconClassNames, getIconA11yAttrs, getIconTitle } from "../utils";

/*
 * Material Icon: Home
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Ahome%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function HomeIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="M264-216h96v-204q0-15.3 10.35-25.65Q380.7-456 396-456h168q15.3 0 25.65 10.35Q600-435.3 600-420v204h96v-348L480-726 264-564v348Zm-72-12v-336q0-16.849 7.5-31.925Q207-611 221-622l216-162q20-14 43-14t43 14l216 162q14 11 21.5 26.075Q768-580.849 768-564v336q0 34.65-24.675 59.325Q718.65-144 684-144H564q-15.3 0-25.65-10.35Q528-164.7 528-180v-204h-96v204q0 15.3-10.35 25.65Q411.3-144 396-144H276q-34.65 0-59.325-24.675Q192-193.35 192-228Zm288-243Z" />
    </svg>
  );
}
