import React from "react";
import cx from "classnames";

export type SeparatorProps = {
  variant?: "light" | "dark";
  size?: "sm" | "lg";
};

export function Separator({ variant = "light", size = "sm" }: SeparatorProps) {
  return (
    <hr
      className={cx({
        "border-border-divider bg-border-divider":
          variant === "light" || variant === "dark",
        "h-px": size === "sm" || size === "lg",
      })}
    />
  );
}
