import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cx from "classnames";
import {
  Button,
  CheckCircleIcon,
  FieldGutterNew,
  RenderStateSection,
  Separator,
} from "@pl/app-component-lib";
import { AccountType, getActiveAccount } from "@pl/app-services";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";
import { useSwitchAccount } from "#app-common/hooks/useSwitchAccount";

export function SelectAccount() {
  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [renderState, setRenderState] = useState<"loading" | "ready">(
    "loading"
  );
  const [fieldState, setFieldState] = useState<"valid" | "error" | "default">(
    "default"
  );
  const { formatMessage } = useIntl();
  const router = usePaymentLabsRouter();
  const { accounts, isLoading, isSwitching, uniqueAccount } =
    useSwitchAccount();

  function isAllowedIndexApp(type: AccountType) {
    return ["merchant", "payor", "payee", "buyer"].includes(type);
  }

  useEffect(() => {
    const activeAccount = getActiveAccount();

    if (
      !isLoading &&
      !uniqueAccount &&
      (!activeAccount ||
        (activeAccount && !isAllowedIndexApp(activeAccount.type)))
    ) {
      setRenderState("ready");
    }
  }, [isLoading, uniqueAccount]);

  useEffect(() => {
    const activeAccount = getActiveAccount();
    if (!isLoading && uniqueAccount) {
      uniqueAccount.actionClick();
    } else if (activeAccount && isAllowedIndexApp(activeAccount.type)) {
      router.goToIndex();
    }
  }, [uniqueAccount, isLoading, router]);

  function handleOnContinue() {
    if (!selectedAccount) {
      setFieldState("error");
      return;
    }

    const account = accounts
      .flatMap((item) => item.items)
      .find((item) => {
        return item?.value === selectedAccount;
      });

    if (account?.type === "standard") {
      account.actionClick();
    }
  }

  function handleOnSelectAccount(payorOption: string) {
    setSelectedAccount(payorOption);
    setFieldState("valid");
  }

  return (
    <>
      {renderState === "loading" && (
        <RenderStateSection variant="loading" height="lg" />
      )}
      {renderState === "ready" && (
        <>
          <h1 className="text-txt-heading text-heading-xs mb-6">
            <FormattedMessage id="login.select-account.heading" />
          </h1>
          <div className="max-h-96 overflow-auto">
            {accounts.map((item, index) => {
              return (
                <Fragment key={index}>
                  {index !== 0 && <Separator />}
                  <div>
                    <p className="text-label-md p-3">{item.label}</p>
                    {item.items &&
                      item.items.map((subItem, subIndex) => (
                        <Fragment key={`${index}-${subIndex}`}>
                          <button
                            className={cx(
                              "text-label-sm text-int-txt-form flex w-full items-center gap-2 rounded-sm p-3",
                              "focus-visible:shadow-spread focus-visible:shadow-int-brand",
                              "hover:bg-int-bg-secondary-hover active:bg-int-bg-secondary-active",
                              {
                                "bg-int-bg-secondary-active":
                                  subItem.value === selectedAccount,
                              }
                            )}
                            onClick={() => {
                              handleOnSelectAccount(subItem.value);
                            }}
                          >
                            {subItem.Icon && <subItem.Icon size="lg" />}
                            <div className="flex-grow text-left">
                              {subItem.label}
                            </div>
                            {subItem.value === selectedAccount && (
                              <CheckCircleIcon />
                            )}
                          </button>
                        </Fragment>
                      ))}
                  </div>
                </Fragment>
              );
            })}
          </div>
          <FieldGutterNew
            id="select-account"
            fieldState={fieldState}
            errorMessage={formatMessage({
              id: "login.select-account.error.please-select-account",
            })}
          />
          <div className="mb-8"></div>
          <Button
            block
            type="primary"
            onClick={handleOnContinue}
            loading={isSwitching}
            text={formatMessage({
              id: "global.action.continue",
            })}
          />
        </>
      )}
    </>
  );
}
