import { useState } from "react";
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import * as Sentry from "@sentry/nextjs";
import { PayeeBasicInfoDto, PayeeDto } from "@paymentlabs/utils-model-external";
import {
  PatchPayeeResponseError,
  PostRequestVerificationUrlResponseSuccess,
  PutPayeeResendInvitationRequest,
  getPayee,
  patchPayee,
  postRequestVerificationUrl,
  putPayeeResendInvitation,
  putVerificationComplete,
} from "#app-services/api/payee";
import { ValidationResult } from "#app-services/api/pl-lib-types";
import {
  POLL_AGGRESSIVE_TIMEOUT,
  POLL_INTERVAL_AGGRESSIVE,
  POLL_INTERVAL_BACKGROUND,
  ReactQueryKeys,
} from "#app-services/hooks/constants";
import { ServiceHook } from "#app-services/hooks/types";
import { useTimeout } from "#app-services/utils/hooks/useDelay";
import { logFatal } from "#app-services/utils/logger";

type UseGetPayeeCore = ServiceHook<PayeeDto, null> & {
  reset: () => void;
};

function useGetPayeeCore(
  config?: Partial<UseQueryOptions<PayeeDto>>
): UseGetPayeeCore {
  const {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    isFetchedAfterMount,
    data,
    refetch,
  } = useQuery({
    queryKey: [ReactQueryKeys.getPayee],
    queryFn: async () => {
      const response = await getPayee();

      if (!response.success) {
        throw new Error(response.data);
      }

      const { userId, email } = response.data;
      Sentry.setUser({ id: userId, email });

      return response.data;
    },
    staleTime: Infinity,
    ...config,
  });

  const returnObj = {
    isLoading: isFetching,
    isInitialLoading: isLoading,
    isSuccess,
    isError,
    error: null,
    data,
    reset: refetch,
  };

  if (config?.refetchOnMount === "always" && !isFetchedAfterMount) {
    returnObj.isSuccess = false;
    returnObj.data = undefined;
  }

  return returnObj;
}

export type UseGetPayee = UseGetPayeeCore;

export function useGetPayee(): UseGetPayee {
  const result = useGetPayeeCore();

  return result;
}

export function usePollGetPayee(): UseGetPayee {
  const [refetchInterval, setRefetchInterval] = useState(
    POLL_INTERVAL_AGGRESSIVE
  );

  useTimeout(
    () => setRefetchInterval(POLL_INTERVAL_BACKGROUND),
    POLL_AGGRESSIVE_TIMEOUT
  );

  const result = useGetPayeeCore({
    refetchInterval,
    // By default, `refetchOnMount` is set to `true`, which will refetch on
    // mount if the data is stale. But when `staleTime` is set to `Infinity`,
    // the data will never be stale, so we need to set `refetchOnMount` to
    // `always` to ensure that the query is refetched on mount. With this
    // approach, the cached data will not be used on the initial render of the
    // calling component, which is necessary when polling for a status change.
    // Otherwise, the cached data will be returned on the initial render, which
    // can prevent the polling from ever starting (like with IDV, which will
    // only poll while there's no workflow error, but if it entered the flow
    // with a workflow error, and that data is pulled before polling begins, it
    // would short circuit and polling would never actually happen).
    refetchOnMount: "always",
  });

  return result;
}

type PatchPayeeArgs = {
  buildSanitizedDto: () => Promise<ValidationResult>;
};

export type UsePatchPayee = ServiceHook<undefined, PatchPayeeResponseError> & {
  patchPayee: (args: PatchPayeeArgs) => void;
};

export function usePatchPayee(): UsePatchPayee {
  const queryClient = useQueryClient();
  const { isPending, isSuccess, isError, error, mutate } = useMutation<
    void,
    PatchPayeeResponseError,
    PatchPayeeArgs,
    undefined
  >({
    mutationKey: [ReactQueryKeys.patchPayee],
    mutationFn: async ({ buildSanitizedDto }) => {
      const validation = await buildSanitizedDto();
      if (!validation.isValid) {
        logFatal({
          message:
            "Encountered client side schema validation failure in usePatchPayee",
          data: {
            validationErrors: validation.validationErrors,
          },
        });

        throw {
          type: "validation",
          details: validation.validationErrors,
        } as PatchPayeeResponseError;
      }

      const sanitizedDto = validation.parsedBody as PayeeBasicInfoDto;
      const response = await patchPayee(sanitizedDto);
      if (!response.success) {
        // This should never happen - if schema validation fails, that means
        // single field validation incorrectly succeeded or the field visibility
        // configuration was wrong.
        if (response.data.type === "validation") {
          logFatal({
            message: `Encountered validation failure response in usePatchPayee`,
            data: {
              validationErrors: validation.validationErrors,
            },
          });
        }
        throw response.data;
      }

      const getPayeeResponse = await getPayee();
      if (!getPayeeResponse.success) {
        throw {
          type: "failure",
        } as PatchPayeeResponseError;
      }
      queryClient.setQueryData(
        [ReactQueryKeys.getPayee],
        getPayeeResponse.data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeys.getAvailablePaymentMethods],
      });
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeys.getPaymentMethodDetailsPrepaid],
      });
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeys.getAvailableTaxForms],
      });
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error,
    patchPayee: mutate,
  };
}

export type UsePostRequestVerificationUrl = ServiceHook<
  PostRequestVerificationUrlResponseSuccess,
  null
> & {
  requestVerificationUrl: (locale: string) => void;
};

export function usePostRequestVerificationUrl(): UsePostRequestVerificationUrl {
  const { isPending, isSuccess, isError, data, mutate } = useMutation({
    mutationKey: [ReactQueryKeys.postRequestVerificationUrl],
    mutationFn: async (locale: string) => {
      const response = await postRequestVerificationUrl({
        theme: "blue",
        locale,
      });

      if (!response.success) {
        throw new Error();
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    data,
    requestVerificationUrl: mutate,
  };
}

export type UsePutVerificationComplete = ServiceHook<undefined, null> & {
  reportVerificationComplete: () => void;
};

export function usePutVerificationComplete(): UsePutVerificationComplete {
  const { isPending, isSuccess, isError, mutate } = useMutation({
    mutationKey: [ReactQueryKeys.putVerificationComplete],
    mutationFn: async () => {
      const response = await putVerificationComplete();

      if (!response.success) {
        throw new Error();
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    reportVerificationComplete: mutate,
  };
}

export type PutPayeeResendInvitation = ServiceHook<undefined, null> & {
  putPayeeResendInvitation: (request: PutPayeeResendInvitationRequest) => void;
};

export function usePutPayeeResendInvitation(): PutPayeeResendInvitation {
  const { isPending, isSuccess, isError, mutate } = useMutation({
    mutationKey: [ReactQueryKeys.putPayeeResendInvitation],
    mutationFn: async (request: PutPayeeResendInvitationRequest) => {
      const response = await putPayeeResendInvitation(request);

      if (!response.success) {
        throw new Error();
      }

      return response.data;
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isPending,
    isSuccess,
    isError,
    error: null,
    putPayeeResendInvitation: mutate,
  };
}
