import { SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Message } from "@pl/app-component-lib";
import { usePostUserLogin } from "@pl/app-services";
import { Password } from "#app-common/components/Password";
import { RhfInput } from "#app-common/components/RhfInput";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";
import { validateNonEmptyString } from "#app-common/models/validators";

function CredentialsWrapped({
  onSuccess,
  payeeEmail,
  payorId,
  withRememberDevice,
  onForgotPasswordNavigation,
}: CredentialsProps) {
  const { formatMessage } = useIntl();
  const router = usePaymentLabsRouter();
  const { login, isLoading, isSuccess, data, isError, error } =
    usePostUserLogin();
  const { handleSubmit, getValues } = useFormContext<CredentialsForm>();

  useEffect(() => {
    if (isSuccess) {
      if (data === "NEEDS_2FA") {
        onSuccess("2fa");
      } else if (data === "SUCCESS") {
        onSuccess("success");
      } else if (data === "NEEDS_CHANGE_PASSWORD") {
        onSuccess("changePassword");
      }
    }
  }, [isSuccess, data, onSuccess]);

  function handleSignIn(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { emailAddress, password } = getValues();

    handleSubmit(() =>
      login({
        username: emailAddress,
        password,
        payorId,
        withRememberDevice,
      })
    )();
  }

  function handleForgotClick() {
    if (onForgotPasswordNavigation) {
      onForgotPasswordNavigation();
    } else {
      router.goToForgotPassword();
    }
  }

  const hardcodedEmail = payeeEmail || "";

  let errorMessage = null;
  if (isError && error) {
    if (error.code === "INVALID_USERNAME_PASSWORD") {
      errorMessage = (
        <FormattedMessage id="login.error.invalid-email-password" />
      );
    } else {
      errorMessage = (
        <FormattedMessage id="global.error.api.something-went-wrong" />
      );
    }
  }

  return (
    <>
      <h1 className="text-txt-heading text-title-lg mb-6">
        <FormattedMessage id="login.heading" />
      </h1>
      {errorMessage && (
        <div className="mb-4">
          <Message status="negative" body={errorMessage} />
        </div>
      )}
      <form onSubmit={handleSignIn}>
        <RhfInput
          id="emailAddress"
          label={formatMessage({
            id: "login.label.email-address",
          })}
          defaultValue={hardcodedEmail}
          disabled={!!hardcodedEmail}
          validate={(value) => validateNonEmptyString({ value, formatMessage })}
        />
        <Password />
        <div className="mb-8 flex justify-end">
          <Button
            type="tertiary"
            text={formatMessage({
              id: "login.action.forgot-password",
            })}
            onClick={handleForgotClick}
          />
        </div>

        <Button
          block
          type="primary"
          htmlType="submit"
          text={formatMessage({
            id: "login.action.sign-in",
          })}
          loading={isLoading}
        />
      </form>
    </>
  );
}

export type CredentialsProps = {
  onSuccess: (step: "2fa" | "success" | "changePassword") => void;
  payeeEmail?: string;
  payorId?: string;
  withRememberDevice?: boolean;
  onForgotPasswordNavigation?: () => void;
};

type CredentialsForm = {
  emailAddress: string;
  password: string;
};

export function Credentials(props: CredentialsProps) {
  const rhfMethods = useForm<CredentialsForm>({
    mode: "onTouched",
  });

  return (
    <FormProvider {...rhfMethods}>
      <CredentialsWrapped {...props} />
    </FormProvider>
  );
}
