import { useIntl } from "react-intl";
import { RhfInput } from "#app-common/components/RhfInput";
import { validateNonEmptyString } from "#app-common/models/validators";

export type PasswordProps = {
  label?: string;
};

export function Password({ label }: PasswordProps) {
  const { formatMessage } = useIntl();

  const formattedLabel = label
    ? label
    : formatMessage({
        id: "login.label.password",
      });

  return (
    <RhfInput
      id="password"
      type="password"
      label={formattedLabel}
      validate={(value) =>
        validateNonEmptyString({
          value,
          formatMessage,
        })
      }
    />
  );
}
