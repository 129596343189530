import { useCallback, useEffect } from "react";
import { IntlShape, useIntl } from "react-intl";
import {
  HomeIcon,
  MenuItemOptionStandard,
  MenuSection,
  PersonIcon,
} from "@pl/app-component-lib";
import {
  AccountType,
  GetUsersPersonasResponse,
  UserPersona,
  getActiveAccount,
  logFatal,
  updateActiveAccount,
  useAuthStore,
  useGetUsersPersonas,
  usePutSwitchAccess,
  useResetQueries,
} from "@pl/app-services";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";

export type AccountGroup = {
  items: AccountItem[];
} & Omit<MenuSection, "items">; // Omit items cause we are using value property

export type AccountItem = {
  value: string;
  items?: AccountItem[];
} & Omit<MenuItemOptionStandard, "items">; // Omit items cause we are using value property

const accountItemValue = (payorId: string, permission: string) =>
  `${payorId}_${permission}`;

function buildStandardAccountItem(
  account: UserPersona,
  handleOnClick: (payorId: string) => void,
  currentActiveAccountType?: AccountType,
  currentActivePayorId?: string
): AccountItem {
  const value = accountItemValue(account.payorId, account.permission);
  return {
    type: "standard",
    Icon: PersonIcon,
    label: account.displayName,
    value: value,
    actionClick: () => handleOnClick(value),
    selected:
      account.payorId === currentActivePayorId &&
      account.permission === currentActiveAccountType,
  };
}

function updateLabel(
  formatMessage: IntlShape["formatMessage"],
  permission: AccountType,
  item: AccountItem
) {
  if (permission === "buyer") {
    item.label = formatMessage({
      id: "dashboard.menu.account-type.buyer-account.label",
    });
    item.Icon = HomeIcon;
  }
}

function buildAccountItemItems(
  formatMessage: IntlShape["formatMessage"],
  usersPersonas: GetUsersPersonasResponse,
  handleOnClick: (payorId: string) => void,
  currentActiveAccountType?: AccountType,
  currentActivePayorId?: string
): Map<string, AccountItem[]> {
  const accountItems = new Map<string, AccountItem[]>([
    ["payee", []],
    ["payor", []],
    ["merchant", []],
    ["buyer", []],
  ]);

  usersPersonas.forEach((account: UserPersona) => {
    const item: AccountItem = buildStandardAccountItem(
      account,
      handleOnClick,
      currentActiveAccountType,
      currentActivePayorId
    );

    updateLabel(formatMessage, account.permission, item);

    if (accountItems.has(account.permission)) {
      const currItems = accountItems.get(account.permission) || [];

      currItems.push(item);

      accountItems.set(account.permission, currItems);
    } else {
      accountItems.set(account.permission, [item]);
    }
  });

  return accountItems;
}

export function useSwitchAccount() {
  useAuthStore();
  const { formatMessage } = useIntl();
  const resetQueries = useResetQueries();
  const {
    data: usersPersonas,
    isLoading: isLoadingAccounts,
    isSuccess,
  } = useGetUsersPersonas();
  const {
    putSwitchAccess,
    isSuccess: isSuccessPutSwitchAccess,
    isLoading: isLoadingSwitchAccess,
    reset,
  } = usePutSwitchAccess();
  const router = usePaymentLabsRouter();

  const activeAccount = getActiveAccount();
  const currentPayorId = activeAccount?.payorId;
  const currentAccountType = activeAccount?.type;

  useEffect(() => {
    if (isSuccessPutSwitchAccess) {
      // There's an edge case we need to account for here. Consider this
      // scenario:
      //
      // 1. User is on some non-dashboard page and switches account from the top
      //    nav profile menu.
      // 2. User is redirected to the dashboard (index), as expected.
      // 3. User then clicks a button to navigate to a different page.
      // 4. User is then redirected again back to the index.
      //
      // The issue is #4 - the user is being redirected back to the index,
      // and is stuck in a loop. This is because this effect is triggered on
      // each navigation, and since the user switched accounts,
      // isSuccessPutSwitchAccess is true, and so this effect runs again causing
      // the loop. This leads to some questions:
      //
      // - Why does this happen if isSuccessPutSwitchAccess remains true,
      //   shouldn't this effect only run once? That's because the effect also
      //   depends on the router, which is as stable as it can be, but will be
      //   regenerated on pathname change. Which is what's happening here - the
      //   pathname changes after the user attempts to navigate away, causing
      //   the router to be regenerated, and this effect to run again.
      // - Why isn't this a bigger issue with other components/pages that have
      //   the router as a dependency? That's because this hook is a little
      //   unique in that it's used on the top nav which never gets unmounted.
      //   Normally when navigating away, the component tree (or at least the
      //   portion calling this hook) is unmounted, and isSuccessPutSwitchAccess
      //   is subsequently reset. But it's never reset in this case.
      //
      // So, the solution here, is to manually reset the mutation's state when
      // we first redirect, so this effect won't run again on any subsequent
      // navigation changes (until the user switches accounts again).
      //
      // There aren't many other ways to approach this. One viable candidate is
      // to split router navigation from any state - so split out the goTo* from
      // the reflection methods that need access to the pathname/basePath. The
      // problem with this is that even the goTo* methods need access to the
      // pathname, like the gateway router's goTo override that conditionally
      // routes internal/external based on the pathname. But this should just be
      // a one off edge case because this hook is on the shell.
      reset();
      resetQueries();
      router.goToIndex();
    }
  }, [isSuccessPutSwitchAccess, router, reset, resetQueries]);

  const handleOnClick = useCallback(
    (accountValue: string) => {
      const [payorId, permission] = accountValue.split("_") as [
        string,
        AccountType,
      ];

      // Shouldn't be possible, if an account is being selected it should be
      // wired up correctly.
      if (!payorId) {
        logFatal({
          message:
            "handleOnClick in useSwitchAccount somehow invoked with invalid account",
        });
        return;
      }

      const isSwitchingToSameAccount =
        payorId === currentPayorId && permission === currentAccountType;

      // If the current account is reselected, there are 2 cases to consider.
      // If it's selected from the top nav, there's nothing to be done. If
      // it's selected from login account selection, we need to go to the
      // index regardless. In both cases it's safe to go to the index - in the
      // case of the top nav, if we're already on the index it'll be a noop,
      // and if we're on a different page it'll just go back there.
      if (isSwitchingToSameAccount) {
        router.goToIndex();
        return;
      }
      // Otherwise we're switching to a different account and it needs to be
      // updated.
      else {
        updateActiveAccount(payorId, permission);
      }

      putSwitchAccess({ payorId, accessRole: permission });
    },
    [currentPayorId, currentAccountType, router, putSwitchAccess]
  );

  const accounts: AccountGroup[] = [];
  if (isSuccess && usersPersonas) {
    const accountsByPermission = buildAccountItemItems(
      formatMessage,
      usersPersonas,
      handleOnClick,
      currentAccountType,
      currentPayorId
    );

    const payorAccounts = accountsByPermission.get("payor") || [];
    if (payorAccounts.length > 0) {
      accounts.push({
        label: formatMessage({
          id: "switch-account.label.payor-accounts",
        }),
        items: payorAccounts,
      });
    }

    const payeeAccounts = accountsByPermission.get("payee") || [];
    if (payeeAccounts.length > 0) {
      accounts.push({
        label: formatMessage({
          id: "switch-account.label.payee-accounts",
        }),
        items: payeeAccounts,
      });
    }

    const merchantAccounts = accountsByPermission.get("merchant") || [];
    if (merchantAccounts.length > 0) {
      accounts.push({
        label: formatMessage({
          id: "switch-account.label.merchant-accounts",
        }),
        items: merchantAccounts,
      });
    }

    // Adding Buyer Account App item
    // For buyer account we don't list each buyer account, instead we list the first as `Buyer`
    // Reasoning: Buyer user should have a centralized app to see all data (subscriptions, payment methods) for all merchants/payor
    const buyerAccounts = accountsByPermission.get("buyer") || [];
    if (buyerAccounts.length > 0) {
      const appItems = buyerAccounts[0];
      accounts.push({
        label: formatMessage({
          id: "switch-account.label.apps",
        }),
        items: [appItems],
      });
    }
  }

  const uniqueAccount =
    accounts.length === 1 &&
    accounts[0].items?.length === 1 &&
    accounts[0].items[0];

  return {
    accounts,
    activeAccountType: currentAccountType,
    uniqueAccount,
    isLoading: isLoadingAccounts,
    isSwitching: isLoadingSwitchAccess,
    isSuccessSwitchAccess: isSuccessPutSwitchAccess,
  };
}
