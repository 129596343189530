import { IconProps } from "../types";
import { getIconClassNames, getIconA11yAttrs, getIconTitle } from "../utils";

/*
 * Material Icon: Person
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Aperson%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function PersonIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="M480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192-264v-24q0-23 12.5-43.5T239-366q55-32 116.292-49 61.293-17 124.5-17Q543-432 604.5-415T721-366q22 13 34.5 34t12.5 44v24q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Q234-192 213-213.15T192-264Zm72 0h432v-24q0-5.176-3.025-9.412Q689.95-301.647 685-304q-46-28-98-42t-107-14q-55 0-107 14t-98 42q-5 4-8 7.724T264-288v24Zm216.212-288Q510-552 531-573.212q21-21.213 21-51Q552-654 530.788-675q-21.213-21-51-21Q450-696 429-674.788q-21 21.213-21 51Q408-594 429.212-573q21.213 21 51 21ZM480-624Zm0 360Z" />
    </svg>
  );
}
