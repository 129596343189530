import { SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Message } from "@pl/app-component-lib";
import { usePutForgotPassword } from "@pl/app-services";
import { RhfInput } from "#app-common/components/RhfInput";

function CreateAccountWrapped({
  onSuccess,
  email,
  payorId,
}: CreateAccountProps) {
  const { formatMessage } = useIntl();
  const { putForgotPassword, isLoading, isSuccess, data, isError } =
    usePutForgotPassword();
  const { handleSubmit, getValues } = useFormContext<CreateAccountForm>();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, data, onSuccess]);

  function handleSignIn(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { email: emailInputValue } = getValues();

    handleSubmit(() =>
      putForgotPassword({
        email: emailInputValue,
        payorId,
        notificationType: "2fa",
      })
    )();
  }

  let errorMessage = null;
  if (isError) {
    errorMessage = (
      <FormattedMessage id="global.error.api.something-went-wrong" />
    );
  }

  return (
    <>
      <h1 className="text-txt-heading text-title-lg mb-6">
        <FormattedMessage id="login.heading" />
      </h1>
      {errorMessage && (
        <div className="mb-4">
          <Message status="negative" body={errorMessage} />
        </div>
      )}
      <form onSubmit={handleSignIn}>
        <RhfInput
          id="email"
          label={formatMessage({
            id: "login.label.email-address",
          })}
          defaultValue={email}
          disabled
          validate={async () => Promise.resolve(true)}
        />

        <Button
          block
          type="primary"
          htmlType="submit"
          text={formatMessage({ id: "login.create-account.submit.label" })}
          loading={isLoading}
        />
      </form>
    </>
  );
}

export type CreateAccountProps = {
  onSuccess: () => void;
  email: string;
  payorId: string;
};

type CreateAccountForm = {
  email: string;
};

export function CreateAccount(props: CreateAccountProps) {
  const rhfMethods = useForm<CreateAccountForm>({
    mode: "onTouched",
    defaultValues: {
      email: props.email,
    },
  });

  return (
    <FormProvider {...rhfMethods}>
      <CreateAccountWrapped {...props} />
    </FormProvider>
  );
}
