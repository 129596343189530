import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import cx from "classnames";
import { zxcvbn } from "@zxcvbn-ts/core";
import { RhfInput } from "#app-common/components/RhfInput";
import { validateNonEmptyString } from "#app-common/models/validators";

type LayoutType = "horizontal" | "vertical";

type PasswordConfirmationForm = {
  newPassword: string;
  confirmPassword: string;
};

function isPasswordStrong(password: string) {
  return zxcvbn(password).score >= 4;
}

export type PasswordConfirmationProps = {
  layout?: LayoutType;
};

export function PasswordConfirmation({
  layout = "vertical",
}: PasswordConfirmationProps) {
  const { formatMessage } = useIntl();
  const { getValues } = useFormContext<PasswordConfirmationForm>();

  async function validatePasswordMatch(
    isNewPasswordField: boolean,
    value?: string
  ) {
    const nonEmptyStringValidation = await validateNonEmptyString({
      value,
      formatMessage,
    });
    if (nonEmptyStringValidation !== true) {
      return nonEmptyStringValidation;
    }

    const { newPassword, confirmPassword } = getValues();

    if (isNewPasswordField && !isPasswordStrong(newPassword)) {
      return formatMessage({
        id: "login.password-confirmation.error.weak-password",
      });
    }

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      return formatMessage({
        id: "login.password-confirmation.error.match-password",
      });
    }

    return true;
  }

  return (
    <div
      className={cx("grid grid-cols-1 gap-4", {
        "sm:grid-cols-2": layout === "horizontal",
      })}
    >
      <RhfInput
        className={cx({
          "col-span-2 sm:col-span-1": layout === "horizontal",
        })}
        id="newPassword"
        type="password"
        label={formatMessage({
          id: "login.password-confirmation.new-password.label",
        })}
        validate={async (value?: string) =>
          Promise.resolve(validatePasswordMatch(true, value))
        }
      />
      <RhfInput
        className={cx({
          "col-span-2 sm:col-span-1": layout === "horizontal",
        })}
        id="confirmPassword"
        type="password"
        label={formatMessage({
          id: "login.password-confirmation.confirm-password.label",
        })}
        validate={async (value?: string) =>
          Promise.resolve(validatePasswordMatch(false, value))
        }
      />
    </div>
  );
}
