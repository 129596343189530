import { SyntheticEvent, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Message, Separator } from "@pl/app-component-lib";
import { usePutChangePassword } from "@pl/app-services";
import { Password } from "#app-common/components/Password";
import { PasswordConfirmation } from "#app-common/components/PasswordConfirmation";

function ChangePasswordWrapped({ onSuccess }: ChangePasswordProps) {
  const { formatMessage } = useIntl();
  const { putChangePassword, isSuccess, isLoading, isError, error } =
    usePutChangePassword();

  const formContext = useFormContext<ChangePasswordForm>();

  function handleFormSubmit(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const { password, newPassword } = formContext.getValues();

    formContext.handleSubmit(() =>
      putChangePassword({
        oldPassword: password,
        newPassword,
      })
    )();
  }

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <div className="size-full justify-between overflow-hidden">
      <h1 className="text-txt-heading text-heading-xs mb-6">
        <FormattedMessage id="login.change-password.heading" />
      </h1>
      {isError && error && (
        <div className="mb-4">
          <Message
            status="negative"
            body={
              <FormattedMessage
                id={
                  error.type === "schemaValidation"
                    ? "global.error.validation.generic"
                    : "global.error.api.something-went-wrong"
                }
              />
            }
          />
        </div>
      )}
      <form onSubmit={handleFormSubmit}>
        <Password
          label={formatMessage({
            id: "login.change-password.current-password.label",
          })}
        />

        <div className="pb-5">
          <Separator />
        </div>
        <PasswordConfirmation />
        <div className="mb-8"></div>
        <Button
          block={true}
          type="primary"
          htmlType="submit"
          text={formatMessage({
            id: "global.action.submit",
          })}
          loading={isLoading}
        />
      </form>
    </div>
  );
}

type ChangePasswordForm = {
  password: string;
  newPassword: string;
};

export type ChangePasswordProps = {
  onSuccess: () => void;
};

export function ChangePassword(props: ChangePasswordProps) {
  const rhfMethods = useForm<ChangePasswordForm>({
    mode: "onTouched",
  });

  return (
    <FormProvider {...rhfMethods}>
      <ChangePasswordWrapped {...props} />
    </FormProvider>
  );
}
