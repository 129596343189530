import { useEffect, useState } from "react";
import { RenderStateSection } from "@pl/app-component-lib";
import { ThemeConfig } from "@pl/app-services";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";
import { ChangePassword } from "../change-password/ChangePassword";
import { TwoFactor } from "../two-factor/TwoFactor";
import { AuthContainer } from "./AuthContainer";
import { CreateAccount } from "./CreateAccount";
import { Credentials } from "./Credentials";
import Guest, { GuestData } from "./Guest";
import { PendingActivation } from "./PendingActivation";
import { SelectAccount } from "./SelectAccount";
import { SetUserInfo } from "./SetUserInfo";

export type LoginProps = {
  isNewUser?: boolean;
  payeeEmail?: string;
  payeeReferenceId?: string;
  payorId?: string;
  theme?: ThemeConfig;
  withFooter?: boolean;
  withRememberDevice?: boolean;
  withSelectAccount?: boolean;
  withGuest?: boolean;
  guestData?: GuestData;
  onSuccess?: () => void;
  onForgotPasswordNavigation?: () => void;
};

type Step =
  | "2fa"
  | "changePassword"
  | "setUserInfo"
  | "credentials"
  | "selectAccount"
  | "pending"
  | "success"
  | "createAccount"
  | "loading";

export function Login({
  isNewUser,
  payeeEmail,
  payeeReferenceId,
  payorId,
  theme,
  withFooter,
  withRememberDevice,
  withSelectAccount,
  withGuest,
  guestData,
  onSuccess,
  onForgotPasswordNavigation,
}: LoginProps) {
  const [loginStep, setLoginStep] = useState<Step>("loading");
  const isCreateAccount = Boolean(isNewUser && payeeEmail);
  const isPendingActivation = Boolean(isCreateAccount && payeeReferenceId);

  useEffect(() => {
    if (isCreateAccount && !isPendingActivation) {
      setLoginStep("createAccount");
    } else if (isPendingActivation) {
      setLoginStep("pending");
    } else {
      setLoginStep("credentials");
    }
  }, [isCreateAccount, isPendingActivation]);

  const router = usePaymentLabsRouter();

  useEffect(() => {
    if (loginStep === "success") {
      if (withSelectAccount) {
        setLoginStep("selectAccount");
      } else if (isCreateAccount) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onSuccess) {
          onSuccess();
        } else {
          router.goToIndex();
        }
      }
    }
  }, [isCreateAccount, loginStep, onSuccess, router, withSelectAccount]);

  return (
    <AuthContainer theme={theme} withFooter={withFooter}>
      {loginStep === "pending" && (
        <PendingActivation
          payeeEmail={payeeEmail!}
          payeeReferenceId={payeeReferenceId!}
        />
      )}
      {loginStep === "createAccount" && (
        <CreateAccount
          email={payeeEmail!}
          onSuccess={() => setLoginStep("success")}
          payorId={payorId!}
        />
      )}
      {loginStep === "credentials" && (
        <Credentials
          onSuccess={(step) => {
            if (step === "success") {
              setLoginStep("setUserInfo");
            } else {
              setLoginStep(step);
            }
          }}
          payeeEmail={payeeEmail}
          payorId={payorId}
          withRememberDevice={withRememberDevice}
          onForgotPasswordNavigation={onForgotPasswordNavigation}
        />
      )}
      {loginStep === "2fa" && (
        <TwoFactor
          onSuccess={(step) => {
            if (step === "success") {
              setLoginStep("setUserInfo");
            } else {
              setLoginStep(step);
            }
          }}
          payorId={payorId}
          withRememberDevice={withRememberDevice}
        />
      )}
      {loginStep === "setUserInfo" && <SetUserInfo onSuccess={setLoginStep} />}
      {loginStep === "changePassword" && (
        <ChangePassword onSuccess={() => setLoginStep("success")} />
      )}
      {loginStep === "selectAccount" && <SelectAccount />}
      {loginStep === "success" ||
        (loginStep === "loading" && (
          <RenderStateSection variant="loading" height="sm" />
        ))}
      {withGuest && loginStep !== "success" && (
        <>
          <div className="mb-8" />
          <Guest
            onSuccess={() => setLoginStep("success")}
            guestData={guestData!}
          />
        </>
      )}
    </AuthContainer>
  );
}
