import { AxiosResponse, AxiosError } from "axios";
import { PayeeDto, PayeeBasicInfoDto } from "@paymentlabs/utils-model-external";
import { instance } from "#app-services/api/config";
import { ApiEndpoints } from "#app-services/api/endpoints";
import { ValidationResponse } from "#app-services/api/pl-lib-types";
import { ServiceResponse } from "#app-services/api/types";

export async function getPayee(): Promise<
  ServiceResponse<PayeeDto, undefined>
> {
  let response: AxiosResponse<PayeeDto>;
  const url = ApiEndpoints.getPayee();

  try {
    response = await instance.get(url);
  } catch (e) {
    const error = e as AxiosError;
    const { message, status } = error;

    return {
      success: false,
      status,
      message,
      data: undefined,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PatchPayeeResponseError =
  | ({
      type: "validation";
    } & ValidationResponse)
  | { type: "failure" };

export async function patchPayee(
  body: PayeeBasicInfoDto
): Promise<ServiceResponse<undefined, PatchPayeeResponseError>> {
  let response: AxiosResponse<undefined>;

  const url = ApiEndpoints.patchPayee();

  try {
    response = await instance.patch(url, body);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;
    const status = response?.status;

    let data: PatchPayeeResponseError = { type: "failure" };
    if (response?.status === 400) {
      data = {
        type: "validation",
        ...(error.response?.data as ValidationResponse),
      };
    }

    return {
      success: false,
      status,
      message,
      data,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PostRequestVerificationUrlRequestBody = {
  theme: "blue" | "green";
  locale: string;
};

// TODO: Replace when utils-model-external types are updated
export type PostRequestVerificationUrlResponseSuccess = {
  verificationUrl: string;
  processingStatus: "processing" | "completed";
};

export async function postRequestVerificationUrl(
  body: PostRequestVerificationUrlRequestBody
): Promise<ServiceResponse<PostRequestVerificationUrlResponseSuccess, null>> {
  let response: AxiosResponse<PostRequestVerificationUrlResponseSuccess>;
  const url = ApiEndpoints.postRequestVerificationUrl();

  try {
    response = await instance.post(url, body);
  } catch (e) {
    const error = e as AxiosError;
    const { message, status } = error;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export async function putVerificationComplete(): Promise<
  ServiceResponse<PostRequestVerificationUrlResponseSuccess, null>
> {
  let response: AxiosResponse<PostRequestVerificationUrlResponseSuccess>;
  const url = ApiEndpoints.putVerificationComplete();

  try {
    response = await instance.put(url);
  } catch (e) {
    const error = e as AxiosError;
    const { message, status } = error;

    return {
      success: false,
      status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: response.data,
    status: response.status,
    raw: response,
  };
}

export type PutPayeeResendInvitationRequest = {
  payeeEmail: string;
  payeeReferenceId: string;
};

export async function putPayeeResendInvitation(
  request: PutPayeeResendInvitationRequest
): Promise<ServiceResponse<null, null>> {
  let response: AxiosResponse;
  const url = ApiEndpoints.putPayeeResendInvitation();

  try {
    response = await instance.put(url, request);
  } catch (e) {
    const error = e as AxiosError;
    const { message, response } = error;

    return {
      success: false,
      status: response?.status,
      message,
      data: null,
      raw: error,
    };
  }

  return {
    success: true,
    data: null,
    status: response.status,
    raw: response,
  };
}
