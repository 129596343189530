import { useController, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Checkbox } from "@pl/app-component-lib";

const FIELD_ID = "trustThisDevice";

export type RememberDeviceField = {
  trustThisDevice: boolean;
};

export function RememberDevice() {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<RememberDeviceField>();
  const { field } = useController({
    name: FIELD_ID,
    control,
  });

  const { onChange: rhfOnChange, value, name } = field;

  return (
    <Checkbox
      id={FIELD_ID}
      name={name}
      label={formatMessage({
        id: "login.2fa.token.remember-device",
      })}
      checked={value}
      onChange={rhfOnChange}
    />
  );
}
